import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, InputGroup } from "@themesberg/react-bootstrap";
import { Field } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Customer from "../../customers/lib/Customer";
import ContactPersonModal from "../../customers/components/ContactPersonModal";

export default function ContractInformation({
  setFieldValue,
  handleBlur,
  errors,
  values,
  touched,
  setShowCustomer,
  customers,
  customerId,
  setCustomerId,
  setMessage
}) {
  const { t } = useTranslation();
  const [contactPersons, setContactPersons] = useState([]);
  const [contactPersonsEmail, setContactPersonsEmail] = useState([]);

  const [showContactPersonModal, setShowContactPersonModal] = useState(false);
  const handleCloseContactPerson = () => setShowContactPersonModal(false);

  useEffect(() => {
    if (customerId) fetchCustomerContactPersons(customerId);
    // eslint-disable-next-line
  }, [customerId]);

  const fetchCustomerContactPersons = (customerId) => {
    Customer.fetchCustomerContactPersons(customerId)
      .then((res) => {
        let array = [];
        let email = [];
        for (let i in res?.contact_persons) {
          const prefix = res.contact_persons[i].prefix
            ? res.contact_persons[i].prefix + " "
            : "";
          array.push({
            label:
              prefix +
              res.contact_persons[i].first_name +
              " " +
              res.contact_persons[i].last_name +
              " (" +
              res.contact_persons[i].email +
              ")",
            value: res.contact_persons[i].id,
          });
          email.push({
            email: res.contact_persons[i].email,
            value: res.contact_persons[i].id,
          });
        }
        setContactPersons(array);

        setContactPersonsEmail(email);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const contactEmail = contactPersonsEmail.filter((contactPersonEmail) => {
      return contactPersonEmail.value === values.contact_person_id;
    });

    if (contactEmail[0]?.email) {
      setFieldValue("email_to", contactEmail[0]?.email);
    }

    // eslint-disable-next-line
  }, [values, setContactPersonsEmail]);

  return (
    <>
      <Card className="col-md-12 col-12 mx-2">
        <Card.Header className="p-2">
          <h5>{t("Contract information")}</h5>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <Form.Group className="col-md-6">
              <Form.Label>{t("Customer")}</Form.Label>
              <InputGroup>
                <Select
                  name="customer_id"
                  placeholder={t("select")}
                  className="form-control p-0"
                  onChange={(e) => {
                    setFieldValue("customer_id", e.value);
                    setCustomerId(e.value);
                  }}
                  options={customers}
                  value={customers?.filter(
                    (customer) => customer.value === values.customer_id
                  )}
                />
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="d-inline"
                  onClick={() => setShowCustomer(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </InputGroup>

              {touched?.customer_id && errors?.customer_id ? (
                <span className="error-message">{t(errors.customer_id)}</span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>{t("Select Contact Person")}</Form.Label>
              <InputGroup>
                <Select
                  name="contact_person_id"
                  placeholder={t("select")}
                  className="form-control p-0"
                  onChange={(e) => setFieldValue("contact_person_id", e.value)}
                  value={
                    contactPersons?.filter(
                      (contactPerson) =>
                        values.contact_person_id === contactPerson.value
                    )?.[0] || null
                  }
                  isDisabled={!values.customer_id}
                  options={contactPersons}
                />
                {customerId && (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="d-inline"
                    onClick={() => setShowContactPersonModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                )}
              </InputGroup>
              {errors.contact_person_id && touched.contact_person_id ? (
                <div className="error-message">{errors.contact_person_id}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 d-flex flex-column">
              <Form.Label>{t("Start date")}</Form.Label>
              <DatePicker
                name="start_date"
                dateFormat="dd-MM-yyyy"
                className="form-control"
                minDate={new Date()}
                maxDate={values?.end_date || ""}
                selected={values?.start_date}
                value={values?.start_date}
                onChange={(date) => setFieldValue("start_date", date)}
                onBlur={handleBlur("start_date")}
                calendarStartDay={1}
              />
              {touched.start_date && errors.start_date ? (
                <span className="error-message">{t(errors.start_date)}</span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2 d-flex flex-column">
              <Form.Label>{t("First Invoice date")}</Form.Label>
              <DatePicker
                name="first_invoice_date"
                dateFormat="dd-MM-yyyy"
                minDate={values.start_date || new Date()}
                maxDate={values?.end_date || ""}
                selected={values?.first_invoice_date}
                value={values?.first_invoice_date}
                className="form-control"
                onChange={(date) => setFieldValue("first_invoice_date", date)}
                onBlur={handleBlur("first_invoice_date")}
              />
              {touched.first_invoice_date && errors.first_invoice_date ? (
                <span className="error-message">
                  {t(errors.first_invoice_date)}
                </span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2">
              <Form.Label>{t("status")}</Form.Label>
              <Field
                as="select"
                className="form-control"
                onChange={(e) => setFieldValue("status", e.target.value)}
                name="status"
                values={values?.status || ""}
              // onBlur={handleBlur("status")}
              >
                <option>{t("Select status")}</option>
                <option key="active" value="active">
                  {t("Active")}
                </option>
                <option key="on_hold" value="on_hold">
                  {t("On-hold")}
                </option>
              </Field>
              {touched.status && errors.status ? (
                <span className="error-message">{t(errors.status)}</span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2">
              <Form.Label>{t("Email")}</Form.Label>
              <Field
                name="email"
                value={values.email || ""}
                className="form-control"
                onChange={(e) => setFieldValue("email", e.target.value)}
              />
              {touched.email && errors.email ? (
                <span className="error-message">{t(errors.email)}</span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2 d-flex flex-column">
              <Form.Label>{t("End date")}</Form.Label>
              <DatePicker
                selected={
                  values?.end_date < values?.start_date
                    ? values?.start_date
                    : values?.end_date
                }
                name="end_date"
                minDate={
                  values?.first_invoice_date || values.start_date || new Date()
                }
                dateFormat="dd-MM-yyyy"
                className="form-control"
                onChange={(date) => setFieldValue("end_date", date)}
                onBlur={handleBlur("end_date")}
              />
              {touched.end_date && errors.end_date ? (
                <span className="error-message">{t(errors.end_date)}</span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2">
              <Form.Label>{t("Frequency")}</Form.Label>
              <Field
                as="select"
                name="frequency"
                className="form-control"
                value={values.frequency || ""}
                onChange={(e) => setFieldValue("frequency", e.target.value)}
              >
                <option value="">{t("Select frequency")}</option>
                <option key="weekly" value="weekly">
                  {t("Weekly")}
                </option>
                <option key="monthly" value="monthly">
                  {t("Monthly")}
                </option>
                <option key="yearly" value="yearly">
                  {t("Yearly")}
                </option>
              </Field>
              {touched.frequency && errors.frequency ? (
                <span className="error-message">{t(errors.frequency)}</span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mt-2">
              <Form.Label>{t("Contract description")}</Form.Label>
              <Form.Control
                name="description"
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              ></Form.Control>
              {touched.description && errors.description ? (
                <span className="error-message">{t(errors.description)}</span>
              ) : null}
            </Form.Group>
          </div>
        </Card.Body>
      </Card>

      <ContactPersonModal
        show={showContactPersonModal}
        handleClose={handleCloseContactPerson}
        handleSave={() => fetchCustomerContactPersons(customerId)}
        customerId={customerId}
      />
    </>
  );
}
