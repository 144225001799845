import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, Card, Form } from "@themesberg/react-bootstrap";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Company from "../../companies/libs/Company";
import AlertDismissible from "../../components/old/AlertDismissible";
import { startLoading, stopLoading } from "../../store/actions/loading";
import { companySettingsSchema } from "../../validation";
import { selectCompanySettings } from "../../store/reducers/settings";
import { getCompanySettings } from "../../store/actions/settings";

function CompanySettings() {
  const companySetting = useSelector(selectCompanySettings);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [logoPreview, setLogoPreview] = useState(companySetting?.logo_url);
  const [message, setMessage] = useState({});
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    dispatch(getCompanySettings());

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!logoPreview) {
      setLogoPreview(companySetting?.logo_url);
    }

    //eslint-disable-next-line
  }, [companySetting?.logo_url]);

  const updateCompanySetting = (values) => {
    try {
      dispatch(startLoading()); // show loading

      let formData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        formData.append(key, value);
      }

      Company.updateCompanySetting(formData)
        .then((res) => {
          if (res.success === true) {
            setMessage({
              show: true,
              message: res.message,
              variant: "success",
            });
            dispatch(stopLoading()); // hide loading
          } else if (res.success === false) {
            dispatch(stopLoading()); // hide loading
            setMessage({
              show: true,
              message: res.message,
              variant: "warning",
            });
          }

          if (res.errors) {
            let array = [];
            for (const key in res.errors) {
              array.push({
                show: true,
                message: res.errors[key][0],
                variant: "danger",
              });
            }
            dispatch(stopLoading()); // hide loading
            setErrors(array);
          }
        })
        .catch((error) => {
          // console.log(error);
          dispatch(stopLoading());
        });
    } catch (error) {
      dispatch(stopLoading()); // hide loading
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item href="/">
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Company settings")}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>{t("Company settings")}</h4>
          <p className="mb-0">{t("Showing all settings of your company")}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          {message.show && (
            <AlertDismissible
              textMessage={message.message}
              variant={message.variant}
            />
          )}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-12">
          {errors &&
            errors.map((message, index) => {
              return (
                <AlertDismissible
                  key={index}
                  textMessage={message.message}
                />
              );
            })}

          <Formik
            enableReinitialize
            initialValues={{
              bank_account: companySetting?.bank_account,
              bank_account_holder: companySetting?.bank_account_holder
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                updateCompanySetting(values);
              }, 1000);
            }}
            validationSchema={companySettingsSchema}
          >
            {({
              setFieldValue,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Card>
                  <Card.Header>
                    <h5>{t("settings")}</h5>
                  </Card.Header>
                  <Card.Body>
                    <div className="row">
                      <div className="col-12">
                        <Form.Group>
                          <Form.Label>{t("Bank Account")}</Form.Label>
                          <Field
                            name="bank_account"
                            className="form-control"
                          />
                          {errors.bank_account && touched.bank_account ? (
                            <span className="error-message">
                              {t(errors.bank_account)}
                            </span>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-12">
                        <Form.Group>
                          <Form.Label>{t("Bank Account Holder")}</Form.Label>
                          <Field
                            name="bank_account_holder"
                            className="form-control"
                          />
                          {errors.bank_account_holder &&
                            touched.bank_account_holder ? (
                            <span className="error-message">
                              {t(errors.bank_account_holder)}
                            </span>
                          ) : null}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <Form.Group>
                          <Form.Label>Logo</Form.Label>
                          <Form.Control
                            type="file"
                            name="logo"
                            onChange={(e) => {
                              setFieldValue("logo", e.target.files[0]);

                              const fileReader = new FileReader();
                              fileReader.onload = () => {
                                if (fileReader.readyState === 2) {
                                  if (fileReader.result) {
                                    setLogoPreview(fileReader.result);
                                  }
                                }
                              };
                              fileReader.readAsDataURL(e.target.files[0]);

                            }}
                          />
                        </Form.Group>
                      </div>
                      {errors?.logo_url && (
                        <span className="error-messege">
                          {t(errors?.logo_url)}
                        </span>
                      )}
                      <div className="col-lg-6 text-center">
                        {(logoPreview) && (
                          <img
                            src={`${logoPreview}`}
                            width="100"
                            height="100"
                            alt="logo"
                          />
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Form.Group className="mt-4">
                  <Button type="submit" disabled={isSubmitting}>
                    {t("Save Settings")}
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default CompanySettings;
